body {
  font-family: 'Roboto', Arial, Helvetica, -apple-system, sans-serif;
  font-size: 1.2em;
  color: #7F8C97;
}

p {
  margin-bottom: 1.8rem;
}

h1 {
  font-family: 'Roboto', Arial, Helvetica, -apple-system, sans-serif;
  font-size: 1.7em;
  font-weight: 900;
  color: black;
  margin-bottom: 2rem;
}

h2 {
  font-family: 'Roboto', Arial, Helvetica, -apple-system, sans-serif;
  font-size: 1.2em;
  font-weight: 900;
  color: black;
  margin-bottom: 2rem;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #EAEAEA;
  margin: 1em 0;
  padding: 0;
}

.top-bar {

  background-color: #8AD5FE;
  padding-right: 2rem;
  height: 5rem;

  .menu-text {
    font-size: 1.6em;
  }

  .menu {
    background-color: #8AD5FE;
  }

  h1 {
    color: white;
  }

  a {
    color: white;
  }
}

.hero {
  height: 700px;
  background-color: #8AD5FE;
  color: white;

  em {
    font-weight: 600;
  }

  background-repeat: no-repeat;
  background-image:
    url("../img/left-cloud.svg"),
    url("../img/right-cloud.svg");
  background-position:
    bottom -47px left -20px,
    bottom -47px right -20px;

  .app-icon {
    margin-bottom: 1.6rem;
  }
  .app-badge {
    margin-top: 2.5rem;
  }
  .producthunt {
    margin-top: 1.5rem;
  }
}

.fourOfour {
  height: 700px;
  background-color: #8AD5FE;
  color: black;

  em {
    font-weight: 600;
  }

  background-repeat: no-repeat;
  background-image:
    url("../img/left-cloud.svg"),
    url("../img/right-cloud.svg");
  background-position:
    bottom -47px left -20px,
    bottom -47px right -20px;

  .app-icon {
    margin-bottom: 1.6rem;
  }
  .app-badge {
    margin-top: 2.5rem;
  }

  h1 {
    font-size: 5rem;
  }

  .button {
    border-radius: 3em;
  }
}

.callout {
  margin-top: 4.5rem;
  margin-bottom: 3rem;

  h1 {
    margin-top: 3rem;
  }
}

.small-app-store {
  margin: -4rem 0 7rem;
}

.major-feature {
  margin-top: 4rem;
  margin-bottom: 4rem;
  .icon {
    margin-top: 0.15rem;
  }
  .small-icon {
    margin-bottom: 0.8rem;
  }
  .phone {
    width: 280px;
    height: 530px;
    background-color: #282F3B;
    border-radius: 40px;
  }
  video {
    border-radius: 3px;
    background-color: #282F3B;
  }
}

.features-grid {
  margin-top: 4rem;
  margin-bottom: 4rem;
  .small-feature {
    margin-bottom: 4rem;
    padding-right: 100px;
  }
}

.call-to-action {
  margin-top: 4rem;
  margin-bottom: 4rem;

  p {
    line-height: 1rem;
  }

  .app-icon {
    border-radius: 20px;

    -webkit-box-shadow: 0px 3px 30px -3px rgba(188,197,201,1);
    -moz-box-shadow: 0px 3px 30px -3px rgba(188,197,201,1);
    box-shadow: 0px 3px 30px -3px rgba(188,197,201,1);

    margin-top: 2rem;
    margin-bottom: 1rem;
  }
}

footer {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.footer-separator {
  margin-top: 9rem;
}

.title-bar {
  background-color: #8AD5FE;
}

.off-canvas {
  background-color: #263342;
  padding-top: 64px;
  a {
    color: white;
    font-weight: 600;
  }
}

.contact-us, .slack {
  margin-top: 8rem;
  padding: 32px;
  border-radius: 10px;

  -webkit-box-shadow: 0px 3px 30px -3px rgba(188,197,201,1);
  -moz-box-shadow: 0px 3px 30px -3px rgba(188,197,201,1);
  box-shadow: 0px 3px 30px -3px rgba(188,197,201,1);

  h1 {
    text-align: center;
    font-size: 3em;
    margin-bottom: 0.3em;
  }

  p {
    text-align: center;
    margin-bottom: 2em;
  }

  label {
    font-family: 'Roboto', Arial, Helvetica, -apple-system, sans-serif;
    font-size: 1.1em;
  }

  input, textarea {
    font-family: 'Roboto', Arial, Helvetica, -apple-system, sans-serif;
    box-shadow: none;
    border-radius: 3px;
    border-color: #BEC4C9;
  }

  .name-error, .email-error, .message-error {
    font-size: 0.8em;
    color: red;
  }

  .send-button {
    color: white;
    background-color: #0082F2;
    border-radius: 1000px;
    padding: 16px 24px;
    margin-top: 1.3em;
    margin-right: 12px;
    transition: transform .3s;

    &:hover {
      filter: brightness(120%);
    }

    &:active {
      filter: brightness(90%);
    }
  }

  #alert-message {
    text-align: center;
    padding: 1em;
    margin-bottom: 2em;
    border-radius: 10px;

    &.success {
      background-color: #CCF4D0;
      color: #26BA31;
    }

    &.error {
      background-color: #FFDFE6;
      color: #FE2C55;
    }
  }
}

.presskit {
  margin-top: 6rem;
  padding: 6rem;
  border-radius: 10px;

  -webkit-box-shadow: 0px 3px 30px -3px rgba(188,197,201,1);
  -moz-box-shadow: 0px 3px 30px -3px rgba(188,197,201,1);
  box-shadow: 0px 3px 30px -3px rgba(188,197,201,1);

  h1 {
    margin-top: 3em;
  }

  h1:first-of-type {
    margin-bottom: -0.1em;
    margin-top: 0;
  }

  h2 {
    font-size: 1.1em;
    color: black;
  }

  img {
    display: block;
    margin-top: 2em;
    margin-bottom: 2em;
  }

  .gray {
    color: #778795;
  }

  .signature {
    text-align: right;
    font-family: 'Short Stack', cursive;
    font-size: 1.6em;
    margin-top: 2em;
    margin-right: 1.5em;
  }

  ol {
    list-style: none;
    counter-reset: item;
    margin-bottom: 2em;

    li {
    counter-increment: item;
    margin-bottom: 5px;
    }

    li:before {
      margin-right: 3px;
      content: counter(item);
      border-radius: 100%;
      font-weight: 900;
      font-size: 1.3em;
      width: 1.2em;
      text-align: center;
      display: inline-block;
    }
  }

}

.faq {

  h1 {
    margin-top: 3em;
  }

  h2 {
    color: #7F8C97;
    font-weight: 400;
    margin-top: 2em;
    margin-bottom: 0.8em;
  }

  .question {
    color: black;
    font-weight: 600;
  }

  ul {
    list-style: none;
    padding-left: 0;
    margin-left: 0;
  }

  li {
    margin-bottom: 0.5em;
  }

  hr {
    margin: 3em 0;
  }
}

.slack {
  input {
    font-size: 1.2em;
    height: 2em;
    padding: 1em;
    text-align: center;
  }

  .server-message {
    margin-top: 1em;
    margin-bottom: -2em;
  }
}

.hidden {
  display: none;
}

.privacy {

  margin-top: 3em;

  h1 {
    font-size: 2em;
  }

  h2 {
    font-size: 1.6em;
    color: black;
  }

  h3 {
    font-size: 1.3em;
    font-weight: bold;
  }
}

// Extra large screen and up
@media screen and (min-width: 1440px) {
  .hero {
    background-size: 601.5px 337.5px;
    background-position:
      bottom -80px left -50px,
      bottom -80px right -50px;
  }
}

// Small screen only
@media screen and (max-width: 1023px) {
  .hero {
    .app-icon {
      margin-top: 4rem;
    }

    img {
      margin-top: 1rem;
    }

    background-size: 200px;
    background-position:
      bottom -40px left -100px,
      bottom -40px right -100px;

    margin-bottom: 8rem;
  }

  .callout {
    margin-top: -3rem;
    margin-bottom: 1rem;
  }

  .major-feature {
    margin-top: 1rem;
    margin-bottom: 1rem;

    .phone {
      margin-top: 2rem;
      margin-bottom: 2rem;
    }
  }

  .call-to-action {
    p {
      line-height: 1.8rem;
    }
  }

  .producthunt {
    h1 {
      font-size: 2em;
    }
  }

  .fourOfour {
    h1 {
      font-size: 3em;
    }
  }
}

.red {
  background-color: red;
}

.green {
  background-color: green;
}
